import {useParams} from "react-router-dom";
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where} from "firebase/firestore";
import {db} from "../Utils/Firebase";
import {useEffect, useState} from "react";
import {Divider, IconButton} from "rsuite";
import {
    BookmarkAddedRounded,
    BookmarkBorderRounded,
    FavoriteBorderRounded,
} from "@mui/icons-material";

function AskeoThreadShow() {
    const {id} = useParams();
    const [thread, setThread] = useState({});

    const [liked, setLiked] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);

    useEffect(() => {
        getThread(id).then(() => console.log("Thread fetched !")).then(isThreadSaved)
    }, [])

    const getThread = async (id) => {
        const threadColl = collection(db, "threads");
        const threadQuery = query(threadColl, where("id", "==", id));
        const threadDoc = await getDocs(threadQuery);

        threadDoc.forEach(t => {
            if (t.data()["id"] === id)
            {
                setThread(t.data());
            }
        })
    }
    const saveThreadToProfile = async () => {

        // Check user status
        if (localStorage.getItem("status") === "School")
        {
            // Add thread to school's saved threads
            const schoolColl = collection(db, "schools");
            const schoolQuery = query(schoolColl);
            const schoolDoc = await getDocs(schoolQuery);
            schoolDoc.forEach(
                s => {
                    if (s.data()["id"] === localStorage.getItem("id"))
                    {
                        const savedThreadRef = collection(db, `students/${s.data()["id"]}/savedThreads`);
                        const saved = addDoc(savedThreadRef, {threadId: thread.id, threadTitle: thread.title});
                    }
            })
        }
        else if (localStorage.getItem("status") === "Student")
        {
            // Add thread to student's saved threads
            const userColl = collection(db, "students");
            const userQuery = query(userColl);
            const userDoc = await getDocs(userQuery);
            userDoc.forEach(
                u => {
                    if (u.data()["id"] === localStorage.getItem("id"))
                    {
                        const savedThreadRef = collection(db, `students/${u.data()["id"]}/savedThreads`);
                        const saved = addDoc(savedThreadRef, {threadId: thread.id, threadTitle: thread.title});
                    }
                }
            )
        }
        else if (localStorage.getItem("status") === "Company")
        {
            // Add thread to company's saved threads
            const userColl = collection(db, "companies");
            const userQuery = query(userColl);
            const userDoc = await getDocs(userQuery);
            userDoc.forEach(
                u => {
                    if (u.data()["id"] === localStorage.getItem("id"))
                    {
                        const savedThreadRef = collection(db, `companies/${u.data()["id"]}/savedThreads`);
                        const saved = addDoc(savedThreadRef, {threadId: thread.id, threadTitle: thread.title});
                    }
                }
            )
        }
    }
    const unSaveThreadToProfile = async () => {

        console.log("Unsave thread to profile");

        // Check user status
        if (localStorage.getItem("status") === "Student")
        {
            const savedThreadColl = collection(db, `students/${localStorage.getItem("id")}/savedThreads`);
            const savedThreadQuery = query(savedThreadColl);
            const savedThreadDoc = await getDocs(savedThreadQuery);
            savedThreadDoc.forEach(
                s => {
                    if (s.data()["threadId"] === thread.id)
                    {
                        deleteDoc(doc(db, `students/${localStorage.getItem("id")}/savedThreads`, s.id));
                        isThreadSaved();
                    }
                }
            )
        }
    }
    const isThreadSaved = async () => {
        const userColl = collection(db, `students/${localStorage.getItem("id")}/savedThreads`);
        const userQuery = query(userColl);
        const userDoc = await getDocs(userQuery);
        userDoc.forEach(
            u => {
                if (u.data()["threadId"] === thread.id)
                {
                    setBookmarked(true);
                    return true;
                }
            }
        )
        setBookmarked(false);
        return false;
    }

    return(
        <div>
            <center>
                <img src={thread.image} alt="Thread image" style={
                    {
                        width: "50",
                        height: "25",
                        borderRadius: "25px"
                    }
                }/>
                <h1>{thread.title}</h1>
                <p>{thread.description}</p>
                <Divider/>
                <IconButton icon={<FavoriteBorderRounded/>} disabled={localStorage.getItem("username") === null} aria-label="add to favorites"/>
                <Divider vertical={true}/>
                <IconButton onClick={bookmarked ? unSaveThreadToProfile : saveThreadToProfile} icon={
                    bookmarked ? <BookmarkAddedRounded/> : <BookmarkBorderRounded/>
                } disabled={localStorage.getItem("username") === null} aria-label="add to favorites"/>
            </center>
        </div>
    )
}

export default AskeoThreadShow;