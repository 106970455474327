import {Avatar, Divider, IconButton, Panel, Table} from "rsuite";
import {Trash, Admin, ChangeList} from '@rsuite/icons'
import {doc, getDoc, updateDoc, deleteDoc} from "firebase/firestore";
import {db} from "../Utils/Firebase";
import {useState} from "react";
const { Column, HeaderCell, Cell } = Table;

const AskeoAdminUsers = (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState(false);

    const setAsAdmin = async (status, id) => {
        if (id !== "")
        {
            setLoading(true);

            const user = await getDoc(doc(db, status, id));

            if (user.exists())
            {
                await updateDoc(doc(db, status, id), "isAdmin", "true")
            }
            setLoading(false);
            window.location.reload();
        }
    }

    const removeAdmin = async (status, id) => {
        if (id !== "")
        {
            setLoading(true);

            const user = await getDoc(doc(db, status, id));

            if (user.exists())
            {
                await updateDoc(doc(db, status, id), "isAdmin", "false")
            }
            setLoading(false);
            window.location.reload();
        }
    }

    const deleteUser = async (status, id) => {
        if (id !== "")
        {
            setLoading(true);

            const user = await getDoc(doc(db, status, id))

            if (user.exists())
            {
                await deleteDoc(doc(db, status, id));
            }
            setLoading(false);
            window.location.reload();
        }
    }

  return(
      <>
          <Panel header="Students list" bordered>
              <Table rowHeight={75} autoHeight data={props.users}>
              <Column align="center" width={70} fixed>
                  <HeaderCell title="Avatar">Avatar</HeaderCell>
                  <Cell dataKey="id">
                      {
                          rowData => <Avatar style={{backgroundColor: "darkcyan"}}>{rowData.username[0]}</Avatar>
                      }
                  </Cell>
              </Column>
              <Column width={100} resizable>
                  <HeaderCell title="Username">Username</HeaderCell>
                  <Cell dataKey="username" />
              </Column>
              <Column width={280} resizable>
                  <HeaderCell title="Email">Email</HeaderCell>
                  <Cell dataKey="email" />
              </Column>
              <Column width={75}>
                  <HeaderCell title="Gender">Gender</HeaderCell>
                  <Cell dataKey="gender" />
              </Column>
              <Column width={100}>
                  <HeaderCell title="Status">Status</HeaderCell>
                  <Cell dataKey="status" />
              </Column>
              <Column width={100}>
                  <HeaderCell title="Subscription date">Subscription date</HeaderCell>
                  <Cell dataKey="subscription" />
              </Column>
              <Column width={100}>
                  <HeaderCell title="Threads">Threads</HeaderCell>
                  <Cell onClick={(value) => console.log(value.target)} dataKey="thread" />
              </Column>
              <Column width={100}>
                  <HeaderCell title="Administrator">Administrator</HeaderCell>
                  <Cell dataKey="isAdmin" />
              </Column>
              <Column width={150}>
                  <HeaderCell>Delete</HeaderCell>
                  <Cell>
                      {
                          rowData => <IconButton appearance="primary" color="red" loading={loading} onClick={
                              () => deleteUser("students", rowData.id)
                          } icon={<Trash/>}>Delete</IconButton>
                      }
                  </Cell>
              </Column>
              <Column width={200}>
                  <HeaderCell>Set as Admin</HeaderCell>
                  <Cell>
                      {
                          // eslint-disable-next-line no-mixed-operators
                          rowData => rowData.isAdmin === "false" && <IconButton appearance="primary" color="violet" loading={loading} onClick={
                              () => setAsAdmin("students", rowData.id)
                              } icon={<Admin/>}>Add admin</IconButton>

                              // eslint-disable-next-line no-mixed-operators
                              || <IconButton color="yellow" appearance="primary" loading={loading} onClick={
                                  () => removeAdmin("students", rowData.id)
                              } icon={<ChangeList/>}>Remove admin</IconButton>
                      }
                  </Cell>
              </Column>
          </Table>
          </Panel>

          <Divider/>

          <Panel header="School list" bordered>
              <Table rowHeight={75} autoHeight data={props.schools}>
                  <Column align="center" width={70} fixed>
                      <HeaderCell title="Avatar">Avatar</HeaderCell>
                      <Cell dataKey="id">
                          {
                              rowData => <Avatar style={{backgroundColor: "darkcyan"}}>{rowData.username[0]}</Avatar>
                          }
                      </Cell>
                  </Column>
                  <Column width={100} resizable>
                      <HeaderCell title="Username">Username</HeaderCell>
                      <Cell dataKey="username" />
                  </Column>
                  <Column width={280} resizable>
                      <HeaderCell title="Email">Email</HeaderCell>
                      <Cell dataKey="email" />
                  </Column>
                  <Column width={100}>
                      <HeaderCell title="Status">Status</HeaderCell>
                      <Cell dataKey="status" />
                  </Column>
                  <Column width={100}>
                      <HeaderCell title="Subscription date">Subscription date</HeaderCell>
                      <Cell dataKey="subscription" />
                  </Column>
                  <Column width={100}>
                      <HeaderCell title="Threads">Threads</HeaderCell>
                      <Cell onClick={(value) => console.log(value.target)} dataKey="thread" />
                  </Column>
                  <Column width={100}>
                      <HeaderCell title="Administrator">Administrator</HeaderCell>
                      <Cell dataKey="isAdmin" />
                  </Column>
                  <Column width={150}>
                      <HeaderCell>Delete</HeaderCell>
                      <Cell>
                          {
                              rowData => <IconButton appearance="primary" color="red" loading={loading} onClick={
                                  () => deleteUser("schools", rowData.id)
                              } icon={<Trash/>}>Delete</IconButton>
                          }
                      </Cell>
                  </Column>
                  <Column width={200}>
                      <HeaderCell>Set as Admin</HeaderCell>
                      <Cell>
                          {
                              // eslint-disable-next-line no-mixed-operators
                              rowData => rowData.isAdmin === "false" && <IconButton appearance="primary" color="violet" loading={loading} onClick={
                                      () => setAsAdmin("schools", rowData.id)
                                  } icon={<Admin/>}>Add admin</IconButton>

                                  // eslint-disable-next-line no-mixed-operators
                                  || <IconButton color="yellow" appearance="primary" loading={loading} onClick={
                                      () => removeAdmin("schools", rowData.id)
                                  } icon={<ChangeList/>}>Remove admin</IconButton>
                          }
                      </Cell>
                  </Column>
              </Table>
          </Panel>
      </>
  );
}

export default AskeoAdminUsers;