import {useEffect, useState} from "react";
import {Container, Content, Header, Nav, Sidebar, Sidenav} from "rsuite";
import AskeoAdminDashboard from "../Components/AskeoAdminDashboard";
import AskeoAdminUsers from "../Components/AskeoAdminUsers";
import {collection, getDocs, query} from "firebase/firestore";
import {db} from "../Utils/Firebase";

const AskeoAdminPage = () => {

    // Launch when component load
    useEffect(() => {
        setAdmins([]);
        setThreads([]);
        setSchools([]);
        setStudents([]);
        setCompanies([]);

        getAllStudents().then(() => console.log("Users got !")).then(() => {
            getAllSchools().then(() => console.log("Schools got !")).then(() => {
                getAllCompanies().then(() => console.log("Companies got !")).then(() => {
                    getAllThreads().then(() => console.log("Threads got !"))
                })
            })
        })
    }, [])

    const [students, setStudents] = useState([]);
    const [schools, setSchools] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [admins, setAdmins] = useState([]);
    const [threads, setThreads] = useState([]);
    const [index, setIndex] = useState("0");

    const getAllStudents = async () => {
        const studentsColl = collection(db, "students");
        const studentsQuery = query(studentsColl);
        const studentsDoc = await getDocs(studentsQuery);

        studentsDoc.forEach(u => {
            const student = u.data();
            setStudents(array => [...array, student]);

            if (student["isAdmin"] === "true")
            {
                setAdmins(array => [...array, student]);
            }
        })
    }

    const getAllSchools = async () => {
        const schoolsColl = collection(db, "schools");
        const schoolsQuery = query(schoolsColl);
        const schoolsDoc = await getDocs(schoolsQuery);

        schoolsDoc.forEach(u => {
            const schools = u.data();
            setSchools(array => [...array, schools]);

            if (schools["isAdmin"] === "true")
            {
                setAdmins(array => [...array, schools]);
            }
        })
    }

    const getAllCompanies = async () => {
        const companiesColl = collection(db, "companies");
        const companiesQuery = query(companiesColl);
        const companiesDoc = await getDocs(companiesQuery);

        companiesDoc.forEach(u => {
            const companies = u.data();
            setCompanies(array => [...array, companies]);

            if (companies["isAdmin"] === "true")
            {
                setAdmins(array => [...array, companies]);
            }
        })
    }

    const getAllThreads = async () => {
        const threadsColl = collection(db, "threads");
        const threadsQuery = query(threadsColl);
        const threadsDoc = await getDocs(threadsQuery);
        
        threadsDoc.forEach(t => {
            const thread = t.data();
            setThreads(array => [...array, thread]);
        })
    }

    return(
        <Container>
            <Sidebar>
                <Sidenav defaultOpenKeys={["3"]} activeKey={index} onSelect={(eventKey) => setIndex(eventKey)}>
                    <Sidenav.Body>
                        <Nav>
                            <Nav.Item eventKey="0">Dashboard</Nav.Item>
                            <Nav.Item eventKey="1">Users</Nav.Item>
                            <Nav.Item eventKey="2">Threads</Nav.Item>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>

            <Container>
                <Header></Header>
                <Content>
                    {
                        index === "0" && (<AskeoAdminDashboard
                            students={students}
                            schools={schools}
                            companies={companies}
                            adminsNb={admins.length}
                            threads={threads}/>)
                    }
                    {
                        index === "1" && (<AskeoAdminUsers
                            users={students}
                            schools={schools}
                        />)
                    }
                </Content>
            </Container>
        </Container>
    );
}

export default AskeoAdminPage;