// Function.js
// This file contain all the functions used in the project
// Author: Andoniaina Nomenjanahary

import {collection, getDocs, query} from "firebase/firestore";
import {db} from "./Firebase";
import {useState} from "react";
import bcrypt from "bcryptjs";

class Utils {

    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    static userInfo = {
        id : "",
        email : "",
        username : "",
        isAdmin : ""
    }

    static userExist = async (email, password) => {

        const status = ['students', 'schools', 'companies'];

        for (let s=0; s<status.length; s++)
        {
            const ref = collection(db, status[s])
            const query_ = query(ref);

            const users_ = await getDocs(query_);
            users_.forEach(u => {
                const bcrypt = require('bcryptjs');
                if (u.data()["email"] === email && bcrypt.compareSync(password, u.data()["password"]) === true) {
                    this.userInfo = {
                        id : u.data()["id"],
                        email : u.data()["email"],
                        username : u.data()["username"],
                        isAdmin: u.data()["isAdmin"],
                        status: u.data()["status"]
                    }

                    return this.userInfo;
                }
            })
        }

        return this.userInfo;
    }

    static reset = () => this.userInfo = {
        id : "",
        email : "",
        username : "",
        isAdmin: ""
    };

    static logoutUser = () => {
        window.localStorage.clear();
    }

    static isAdmin = async (email, username) => {
        const [inputIsAdmin, setInputIsAdmin] = useState(false);

        if (window.localStorage.length !== 0 && window.localStorage.getItem("isAdmin") === "true")
        {
            setInputIsAdmin(true);
        }

        return inputIsAdmin;
    }
}

export default Utils;