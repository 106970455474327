import AskeoAddThread from "../Components/AskeoAddThread";
import AskeoListThread from "../Components/AskeoListThread";
import {Col, Divider, Grid, Row} from "rsuite";

const AskeoThreads = () => {
    return(
        <div>
            <Grid fluid>
                <Row>
                    <Col>
                        <h3>Threads</h3>
                    </Col>
                    <Col>
                        <AskeoAddThread/>
                    </Col>
                </Row>
            </Grid>
            <Divider/>
            <AskeoListThread/>
        </div>
    );
}

export default AskeoThreads;