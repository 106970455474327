import {Navbar, Nav, Avatar, Divider} from 'rsuite';
import {Link, useLocation} from "react-router-dom";
import Utils from "../Utils/Functions";

const handleLogout = () => {
    Utils.logoutUser();
    window.location.reload();
}

const AskeoNavBar = () => {
    return(
        <Navbar>
            <Navbar.Brand active={useLocation().pathname === "/"} as={Link} to="/">ASKEO</Navbar.Brand>
            <Nav pullRight>
                {
                    (window.localStorage.length !== 0 && window.localStorage.getItem("isAdmin") === "true") &&
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    <Nav.Item active={useLocation().pathname === "/admin"} as={Link} to="/admin">Admin</Nav.Item>
                }
                <Nav.Item active={useLocation().pathname === "/"} as={Link} to="/">Home</Nav.Item>
                <Nav.Item active={useLocation().pathname === "/threads"} as={Link} to="/threads">Threads</Nav.Item>
                {
                    (window.localStorage.length === 0) && (
                        <Nav.Menu hidden={window.localStorage.length !== 0} title="Login / Signup">
                            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                            <Nav.Item active={useLocation().pathname === "/login" ||  useLocation().pathname === "/signup"} as={Link} to="/login" disabled={window.localStorage.length !== 0}>Login/Signup</Nav.Item>
                        </Nav.Menu>
                    )
                }
                {
                    (window.localStorage.length !== 0) && (
                        <Nav.Menu hidden={window.localStorage.length === 0} title={
                            <>
                            <Avatar style={{backgroundColor: "darkcyan"}}>{window.localStorage.getItem("username").charAt(0)}</Avatar>
                                <Divider vertical/>
                                {
                                    window.localStorage.getItem("isAdmin") === "true" ? window.localStorage.getItem("username") + " (Admin)" : " " + window.localStorage.getItem("username")
                                }
                            </>
                        }>
                            <Nav.Item as={Link} to="/profile" disabled={window.localStorage.length === 0}>Profile</Nav.Item>
                            <Nav.Item onClick={handleLogout} disabled={window.localStorage.length === 0}>Logout</Nav.Item>
                        </Nav.Menu>
                    )
                }
            </Nav>
        </Navbar>
    );
}

export default AskeoNavBar;
