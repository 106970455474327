import AskeoNavBar from "../Components/AskeoNavBar";
import HomePage from "./HomePage";
import {Container, Content, Header} from 'rsuite';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import AskeoThreads from "./AskeoThreads";
import AskeoLoginPage from "./AskeoLoginPage";
import AskeoSignupPage from "./AskeoSignupPage";
import AskeoAdminPage from "./AskeoAdminPage";
import AskeoProfilePage from "./AskeoProfilePage";
import AskeoThreadShow from "../Components/AskeoThreadShow";

const AskeoMainPage = () => {

    return(
        <div>
            <BrowserRouter>
                <Container>
                    <Header>
                        <AskeoNavBar/>
                    </Header>
                    <Content>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/threads" element={<AskeoThreads/>}/>
                            <Route path="/threads/:id" element={<AskeoThreadShow/>}/>
                            <Route path="/login" element={<AskeoLoginPage/>}/>
                            <Route path="/signup" element={<AskeoSignupPage/>}/>
                            <Route path="/profile" element={<AskeoProfilePage/>}/>
                            {
                                (window.localStorage.length !== 0 && window.localStorage.getItem("isAdmin") === "true") ?
                                    <Route path="/admin" element={<AskeoAdminPage/>}/> :
                                    <Route path="/admin" element={<Navigate to="/login"/>}/>
                            }
                        </Routes>
                    </Content>
                </Container>
            </BrowserRouter>
        </div>
    );
}

export default AskeoMainPage;