import {Button, Col, Divider, FlexboxGrid, Form, Modal, Panel} from "rsuite";
import {useState} from "react";
import Utils from "../Utils/Functions";
import {Link} from "react-router-dom";

const AskeoLoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    // Notification
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const loginUser = async () => {
        setLoading(true);
        await Utils.userExist(email, password).then(res => {
            if (res.email !== "" && res.username !== "") {

                // Display a success message
                handleMessageOpen("Successfully connected !");

                // Create a session for the user (local storage)
                window.localStorage.setItem("id", res.id);
                window.localStorage.setItem("email", res.email);
                window.localStorage.setItem("username", res.username);
                window.localStorage.setItem("isAdmin", res.isAdmin);
                window.localStorage.setItem("status", res.status);
            } else {
                // Display an error message
                handleMessageOpen("Incorrect email or password !")
            }
        });
        Utils.reset();
        setLoading(false);
    }

    const handleMessageOpen = (message) => {
        setMessage(message);
        setOpen(true);
    }
    const handleMessageClose = () => {
        setMessage("");
        setOpen(false);
        window.location.reload();
    }

    return(
        <div>
            <Modal backdrop="static" role="alertdialog" open={open} onClose={handleMessageClose} size="xs">
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleMessageClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleMessageClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <FlexboxGrid justify="center">
                <Col>
                    <Panel style={{backgroundColor: "darkslateblue"}} header={<h3>Login to ASKEO</h3>} bordered>
                        <Form onSubmit={loginUser}>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control disabled={loading} value={email} onChange={setEmail} name="email" type="email" required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control disabled={loading} value={password} onChange={setPassword} name="password" type="password" required/>
                            </Form.Group>
                            <Button disabled={window.localStorage.length !== 0} appearance="primary" type="submit" loading={loading}>Login</Button>
                            <Divider vertical/>
                            <Button appearance="link" as={Link} to="/signup" loading={loading}>Create an account</Button>
                        </Form>
                    </Panel>
                </Col>
            </FlexboxGrid>
        </div>
    );
}

export default AskeoLoginPage;