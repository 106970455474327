import {useEffect, useState} from "react";
import {addDoc, collection, getDocs, query, updateDoc} from "firebase/firestore";
import {db} from "../Utils/Firebase";
import {Button, Container, Divider, Form, Modal, SelectPicker} from "rsuite";
import {Link} from "react-router-dom";

const AskeoAddUser = () => {
    useEffect(() => {
        const getSchoolList = async () => {
            const schoolRef = collection(db, "schools");
            const _query = query(schoolRef);
            return await getDocs(_query);
        }
        setSchoolList([]);
        getSchoolList().then(
            doc => {
                doc.forEach(school => {
                    setSchoolList(array => [...array, school.data()["username"]]);
                });
                console.log("School list fetched !");
            }
        );
    }, [])

    const bcrypt = require('bcryptjs');
    const salt = bcrypt.genSaltSync(10);

    // For all user type
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAdmin] = useState("false");
    const [isLoading, setIsLoading] = useState(false);

    // Status of user
    const [status, setStatus] = useState("Student");
    const userStatus = ['Student', 'School', 'Company'].map(item => ({
        label : item,
        value : item
    }));

    // For Students
    const [gender, setGender] = useState("")
    const genderType = ['Male', 'Female'].map(item => ({
        label: item,
        value: item
    }));
    const [studyAt, setStudyAt] = useState("");
    const [schoolList, setSchoolList] = useState([]);
    const atSchool = schoolList.map(item => ({
        label : item,
        value : item
    }))

    // For Company
    const [company, setCompany] = useState("");
    const companyType = ['IT Company', 'Call center', 'Data center', 'Other'].map(item => ({
        label : item,
        value : item
    }));

    // For School
    const [school, setSchool] = useState("");
    const schoolType = ['IT School', 'Management', 'Communication', 'Other'].map(item => ({
        label : item,
        value : item
    }));

    // Notification
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleMessageOpen = (message) => {
        setMessage(message);
        setOpen(true);
    }

    const handleMessageClose = () => {
        setMessage("");
        setOpen(false);
    }

    const addUser = async () => {
        if (status === "Student")
        {
            try {
                const current = new Date();
                const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
                const student = {
                    status: status,
                    username: username,
                    email: email,
                    password: password,
                    gender: gender,
                    thread: 0,
                    subscription: date,
                    isAdmin: isAdmin
                }
                setIsLoading(true);
                const newUser = await addDoc(collection(db, "students"), student);
                await updateDoc(newUser, {
                    id: newUser.id,
                    status: status,
                    username: username,
                    email: email,
                    password: bcrypt.hashSync(password, salt),
                    gender: gender,
                    thread: 0,
                    subscription: date,
                    isAdmin : isAdmin
                })

                setIsLoading(false);
                setUsername("");
                setStatus("");
                setGender("");
                setEmail("");
                setPassword("");
                handleMessageOpen("User added successfully !");
            }
            catch (e) {
                handleMessageOpen("Error adding user !");
            }
        }
        else if (status === "Company")
        {
            try {
                const current = new Date();
                const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
                const _company = {
                    status: status,
                    username: username,
                    email: email,
                    password: password,
                    company: company,
                    thread: 0,
                    subscription: date,
                    isAdmin: isAdmin
                }
                setIsLoading(true);
                const newCompany = await addDoc(collection(db, "companies"), _company);
                await updateDoc(newCompany, {
                    id: newCompany.id,
                    status: status,
                    username: username,
                    email: email,
                    password: bcrypt.hashSync(password, salt),
                    company: company,
                    thread: 0,
                    subscription: date,
                    isAdmin : isAdmin
                })

                setIsLoading(false);
                setUsername("");
                setStatus("");
                setGender("");
                setEmail("");
                setPassword("");
                handleMessageOpen("Company added successfully !");
            }
            catch (e) {
                handleMessageOpen("Error adding user !");
            }
        }
        else if (status === "School")
        {
            try {
                const current = new Date();
                const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
                const _school = {
                    status: status,
                    username: username,
                    email: email,
                    password: password,
                    school: school,
                    thread: 0,
                    subscription: date,
                    isAdmin: isAdmin
                }
                setIsLoading(true);
                const newSchool = await addDoc(collection(db, "schools"), _school);
                await updateDoc(newSchool, {
                    id: newSchool.id,
                    status: status,
                    username: username,
                    email: email,
                    password: bcrypt.hashSync(password, salt),
                    school: school,
                    thread: 0,
                    subscription: date,
                    isAdmin : isAdmin
                })

                setIsLoading(false);
                setUsername("");
                setStatus("");
                setGender("");
                setEmail("");
                setPassword("");
                handleMessageOpen("School added successfully !");
            }
            catch (e) {
                handleMessageOpen("Error adding user !");
            }
        }
    }

    return(
        <div>
            <Modal backdrop="static" role="alertdialog" open={open} onClose={handleMessageClose} size="xs">
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleMessageClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleMessageClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Container>
                <Form onSubmit={addUser}>
                    <Form.Group>
                        <Form.ControlLabel>Your are </Form.ControlLabel>
                        <Form.Control disabled={isLoading} onChange={setStatus} value={status} name="status" data={userStatus} accepter={SelectPicker} required/>
                    </Form.Group>
                    {
                        (status === "Student") &&
                        <>
                            <Form.Group>
                                <Form.ControlLabel>Username</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={username} onChange={setUsername} name="username" required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Study at</Form.ControlLabel>
                                <Form.Control disabled={isLoading} onChange={setStudyAt} value={studyAt} name="gender" data={atSchool} accepter={SelectPicker} required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={email} onChange={setEmail} type="email" name="email" required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={password} onChange={setPassword} type="password" name="password" required/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Gender</Form.ControlLabel>
                                <Form.Control disabled={isLoading} onChange={setGender} value={gender} name="gender" data={genderType} accepter={SelectPicker} required/>
                            </Form.Group>
                            <Button disabled={window.localStorage.length !== 0} appearance="primary" loading={isLoading} type="submit">Create account</Button>
                            <Divider vertical/>
                            <Button appearance="link" loading={isLoading} as={Link} to="/login">Login</Button>
                        </>
                    }
                    {
                        (status === "School") &&
                        <>
                            <Form.Group>
                                <Form.ControlLabel>School name</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={username} onChange={setUsername} name="username"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>School email</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={email} onChange={setEmail} type="email" name="email"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={password} onChange={setPassword} type="password" name="password"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>School type</Form.ControlLabel>
                                <Form.Control disabled={isLoading} onChange={setSchool} value={school} name="gender" data={schoolType} accepter={SelectPicker}/>
                            </Form.Group>
                            <Button disabled={window.localStorage.length !== 0} appearance="primary" loading={isLoading} type="submit">Create account</Button>
                            <Divider vertical/>
                            <Button appearance="link" loading={isLoading} as={Link} to="/login">Login</Button>
                        </>
                    }
                    {
                        (status === "Company") &&
                        <>
                            <Form.Group>
                                <Form.ControlLabel>Company name</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={username} onChange={setUsername} name="username"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Company email</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={email} onChange={setEmail} type="email" name="email"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Password</Form.ControlLabel>
                                <Form.Control disabled={isLoading} value={password} onChange={setPassword} type="password" name="password"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.ControlLabel>Company type</Form.ControlLabel>
                                <Form.Control disabled={isLoading} onChange={setCompany} value={company} name="gender" data={companyType} accepter={SelectPicker} required/>
                            </Form.Group>
                            <Button disabled={window.localStorage.length !== 0} appearance="primary" loading={isLoading} type="submit">Create account</Button>
                            <Divider vertical/>
                            <Button appearance="link" loading={isLoading} as={Link} to="/login">Login</Button>
                        </>
                    }
                </Form>
            </Container>
        </div>
    );
}

export default AskeoAddUser;