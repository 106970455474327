import {Col, Grid, Row} from "rsuite";
import {TypeAnimation} from "react-type-animation";

const HomePage = () => {
    return(
        <div>
            <Grid fluid>
                <Row style={{height: "100%", width: "auto"}}>
                    <Col xs={6} md={12} lg={24}>
                        <center>
                            <h1>Welcome to Askeo</h1>
                            <p>Your preferred Q&A platform !</p>
                            <img src={process.env.PUBLIC_URL + "/ask_illustration-removebg-preview.png"} alt="Askeo Logo" style={{width: "35%", height: "auto"}}/>
                            <TypeAnimation
                                sequence={["Ask anything",1000, "Get answers",2000, "Help others", 3000]}
                                wrapper="div"
                                cursor={true}
                                repeat={Infinity}
                                style={{ fontSize: '2em' }}
                                wrapperStyle={{ color: 'darkslateblue' }}
                                cursorStyle={{ color: 'darkslateblue' }}
                            />
                        </center>
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}
export default HomePage;