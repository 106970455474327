import {useEffect, useState} from "react";
import {collection, getDocs, query} from "firebase/firestore";
import {db} from "../Utils/Firebase";
import {Divider, PanelGroup, Placeholder, Tag} from "rsuite";
import {Card, CardActions, CardContent, IconButton} from "@mui/material";
import {FavoriteBorderRounded, MoreHorizTwoTone} from "@mui/icons-material";
import {Link} from "react-router-dom";

const AskeoListThread = () => {

    // Call data fetching function when component load
    useEffect(() => {
        setThreads([]);
        getAll().then(r => console.log("Data fetched !"));
    }, [])

    const [threads, setThreads] = useState([])
    const [loading, setLoading] = useState(false);

    const getAll = async () => {
        setLoading(true);
        const thRef = collection(db, "threads");
        const query_ = query(thRef);
        const threads_ = await getDocs(query_);

        threads_.forEach(t => {
            const data = t.data();
            setThreads(arr => [...arr, data])
        });
        setLoading(false);
    }

    if (!loading) {
        return(
            <PanelGroup>
                {
                    threads.map(
                        (th) => (
                            <div id={th.id}>
                                {/*Data display using rsuite card to display all fetched data like post*/}
                                <Card onClick={() => console.log(th.id)}>
                                    <CardContent>
                                        {/*Ajust image size to have like parent size*/}
                                        {/*<img style={{width: "100%", height: "auto"}} src={th.image} alt={th.image}/>*/}
                                        <h3>{th.title}</h3>
                                        <p>{th.description}</p>
                                        <Tag color="green">{th.section}</Tag>
                                    </CardContent>
                                    <CardActions>
                                        {/*Disable all card action if localstorage is empty*/}
                                        <IconButton disabled={localStorage.getItem("username") === null} aria-label="add to favorites">
                                            <FavoriteBorderRounded/>
                                        </IconButton>
                                        <Link to={"/threads/" + th.id}>
                                            <IconButton>
                                                <MoreHorizTwoTone/>
                                            </IconButton>
                                        </Link>
                                    </CardActions>
                                </Card>
                                <Divider/>
                            </div>
                        )
                    )
                }
            </PanelGroup>
        );
    } else {
        return(
            <div>
                <Card>
                    <CardContent>
                        <Placeholder active rows={3}/>
                    </CardContent>
                    <CardActions>
                        <IconButton>
                            <FavoriteBorderRounded disable/>
                        </IconButton>
                    </CardActions>
                </Card>
                <Divider/>
                <Card>
                    <CardContent>
                        <Placeholder active rows={3}/>
                    </CardContent>
                    <CardActions>
                        <IconButton>
                            <FavoriteBorderRounded disable/>
                        </IconButton>
                    </CardActions>
                </Card>
                <Divider/>
                <Card>
                    <CardContent>
                        <Placeholder active rows={3}/>
                    </CardContent>
                    <CardActions>
                        <IconButton>
                            <FavoriteBorderRounded disable/>
                        </IconButton>
                    </CardActions>
                </Card>
                <Divider/>
            </div>
        );
    }
}

export default AskeoListThread;