import {Button, Divider, Form, IconButton, Loader, Modal, SelectPicker, Stack} from "rsuite";
import {AddOutline, WarningRound} from '@rsuite/icons'
import {db, storage} from '../Utils/Firebase';
import {addDoc, collection, updateDoc} from 'firebase/firestore';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {useState} from "react";
import {Alert} from "@mui/material";
import {Link} from "react-router-dom";

const askeoAddThread = () => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [title, setTitle] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [description, setDescription] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setIsSuccess] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imageDesc, setImageDesc] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imgUrl, setImageUrl] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [onUpload, setOnUpload] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [section, setSection] = useState("Python");
    const sections = ['Linux', 'Windows', 'Python', 'C#', 'C++', "Java", "Javascript", "UI/UX"].map(item => ({
        label : item,
        value : item
    }));

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false);
        if (title !== "") {
            window.location.reload();
        }
    }
    const addThread = async () => {
        try {
            setIsLoading(true);

            const threadCollection = collection(db, "threads");
            const addedDoc = await addDoc(
                threadCollection,
                {
                    id : "",
                    userId : window.localStorage.getItem("id"),
                    section : section,
                    title: title,
                    description : description,
                    image : imgUrl,
                    email : window.localStorage.getItem("email"),
                    username : window.localStorage.getItem("username")
                });
            await updateDoc(addedDoc, {id : addedDoc.id, title : title, description : description})

            setIsLoading(false);
            setIsSuccess(true);

            handleClose();
            setTitle("");
            setDescription("")
        } catch (e) {
            setIsSuccess(false);
        }
    }
    const uploadFile = (image) => {
        if (image !== null)
        {
            setOnUpload(true);
            const imagesRef = ref(storage, `/images/${image.name}`);
            uploadBytes(imagesRef, image).then((snapshot) => {
                console.log('Uploaded !');
            }).then(() => getDownloadURL(imagesRef).then((url) => setImageUrl(url))
            ).then(() => console.log("Updated !")).then(() => setOnUpload(false))
        }
    }

    return(
        <div>
            <Stack divider={<Divider vertical/>}>
                <IconButton appearance="primary" onClick={handleOpen} disabled={window.localStorage.length === 0} icon={<AddOutline/>}>New thread</IconButton>
                {
                    window.localStorage.length === 0 &&
                    <Alert style={{color: "white"}} severity="warning" variant="outlined" icon={<WarningRound/>}><b>You need to <Link to="/login">login</Link> to add thread !</b></Alert>
                }
            </Stack>
            <Modal open={openModal} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>New thread</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={addThread}>
                        <div>
                            <Form.Group>
                                <Form.ControlLabel>Image</Form.ControlLabel>
                                <input type="file" disabled={onUpload} onChange={
                                    (event) => {
                                        uploadFile(event.target.files.item(0))
                                    }
                                }/>
                            </Form.Group>
                            {
                                onUpload && <Loader center content="Uploading" />
                            }
                        </div>
                        <Form.Group>
                            <Form.ControlLabel>Title</Form.ControlLabel>
                            <Form.Control value={title} disabled={isLoading} onChange={setTitle} name="title"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Description</Form.ControlLabel>
                            <Form.Control value={description} disabled={isLoading} onChange={setDescription} name="description"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Section</Form.ControlLabel>
                            <Form.Control disabled={isLoading} onChange={setSection} value={section} name="gender" data={sections} accepter={SelectPicker}/>
                        </Form.Group>
                        <Button type="submit" appearance="primary" loading={isLoading}>
                            Add
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default askeoAddThread;