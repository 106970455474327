import {collection, getDocs, query} from "firebase/firestore";
import {db} from "../Utils/Firebase";
import {useEffect, useState} from "react";
import {Avatar, Container, Divider, FlexboxGrid, IconButton, Panel} from "rsuite";
import {CalendarMonth, Edit, Info, VerifiedUser} from "@mui/icons-material";

const AskeoProfilePage = () => {

    useEffect(() => {
        const id = window.localStorage.getItem("id");
        getUserInfo(id).then(data => console.log("User info fetched !"))
    }, [])

    const [userInfo, setUserInfo] = useState({});
    const [avatarText, setAvatarText] = useState("");

    const getUserInfo = async (id) => {

        let infoData = {}

        if (localStorage.getItem("status") === "School")
        {
            const userColl = collection(db, "schools");

            const schoolQuery = query(userColl);
            const userDoc = await getDocs(schoolQuery);

            userDoc.forEach(u => {
                console.log("Schools : " + u.data());
                if (u.data()["id"] === id)
                {
                    setAvatarText(u.data()["username"].charAt(0));
                    infoData = u.data();
                    setUserInfo(u.data());
                }
            })
        }
        else if (localStorage.getItem("status") === "Student")
        {
            const userColl = collection(db, "students");

            const studentQuery = query(userColl);
            const userDoc = await getDocs(studentQuery);

            userDoc.forEach(u => {
                console.log("Students : " + u.data());
                if (u.data()["id"] === id)
                {
                    setAvatarText(u.data()["username"].charAt(0));
                    infoData = u.data();
                    setUserInfo(u.data());
                }
            })
        }
        else if (localStorage.getItem("status") === "Company")
        {
            const userColl = collection(db, "companies");

            const companyQuery = query(userColl);
            const userDoc = await getDocs(companyQuery);

            userDoc.forEach(u => {
                console.log("Companies : " + u.data());
                if (u.data()["id"] === id)
                {
                    setAvatarText(u.data()["username"].charAt(0));
                    infoData = u.data();
                    setUserInfo(u.data());
                }
            })
        }

        return infoData;
    }

    return(
        (window.localStorage.length !== 0) &&
            <>
                <Container>
                    <FlexboxGrid justify="center" align="middle">
                        <Panel header={<IconButton icon={<Edit/>}/>} bordered style={{backgroundColor: "darkslategray"}}>
                            <center>
                                <Avatar size="lg" circle style={{backgroundColor: "darkblue"}}>{avatarText}</Avatar>
                                <h3>{window.localStorage.getItem("username")} ({window.localStorage.getItem("email")})</h3>
                            </center>
                            <Divider/>
                            <h5><CalendarMonth/> Member since : {userInfo["subscription"]}</h5>
                            <Divider/>
                            <h5><Info/> Status : {userInfo["status"]}</h5>
                            <Divider/>
                            <h5><VerifiedUser/> User type : {(userInfo["isAdmin"] === "true") ? "Admin" : "User"}</h5>
                        </Panel>
                    </FlexboxGrid>
                </Container>
            </>
    );
}

export default AskeoProfilePage;