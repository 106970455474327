import {Col, Divider, Panel, Placeholder, Row} from "rsuite";

const AskeoAdminDashboard = (props) => {
    return(
        <>
        <Row>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>{props.students.length}</h3>} style={{backgroundColor: "darkgoldenrod"}}>
                    <h5>students registered !</h5>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>{props.schools.length}</h3>} style={{backgroundColor: "darkcyan"}}>
                    <h5>schools registered !</h5>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>{props.companies.length}</h3>} style={{backgroundColor: "darkgreen"}}>
                    <h5>companies registered !</h5>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>{props.adminsNb}</h3>} style={{backgroundColor: "darkred"}}>
                    <h5>admins registered !</h5>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>{props.threads.length}</h3>} style={{backgroundColor: "darkslategray"}}>
                    <h5>threads created !</h5>
                </Panel>
            </Col>
        </Row>
            <Divider/>
        {/*Display statistic : total of users and how much percent is students and schools*/}
        <Row>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>Students</h3>} style={{backgroundColor: "darkgoldenrod"}}>
                    <Placeholder.Paragraph rows={5} active/>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>Schools</h3>} style={{backgroundColor: "darkcyan"}}>
                    <Placeholder.Paragraph rows={5} active/>
                </Panel>
            </Col>
            <Col xs={24} md={12} lg={8}>
                <Panel shaded bordered header={<h3>Companies</h3>} style={{backgroundColor: "darkgreen"}}>
                    <Placeholder.Paragraph rows={5} active/>
                </Panel>
            </Col>
        </Row>

        </>
    );
}

export default AskeoAdminDashboard;