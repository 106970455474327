import AskeoAddUser from "../Components/AskeoAddUser";
import {Col, FlexboxGrid, Panel} from "rsuite";

const AskeoSignupPage = () => {
    return(
        <div>
            <FlexboxGrid justify="center">
                <Col>
                    <Panel style={{backgroundColor: "darkslateblue"}} header={<h3>Register</h3>} bordered>
                        <AskeoAddUser/>
                    </Panel>
                </Col>
            </FlexboxGrid>
        </div>
    );
}

export default AskeoSignupPage;