import AskeoMainPage from "./Pages/AskeoMainPage";

function App() {
  return (
    <div className="App">
        <AskeoMainPage/>
    </div>
  );
}

export default App;
