import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Importation de rsuite
import 'rsuite/dist/rsuite.min.css';

// Mise en place du dark mode
import {CustomProvider} from "rsuite";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CustomProvider theme="dark">
        <App />
    </CustomProvider>
);
